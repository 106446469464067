import React, { useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode, Thumbs } from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import './VT.css'

const host = 'http://192.168.1.36:1337';

const tabs = [
  {
    id: 'industrial',
    icon: 'fas fa-industry',
    title: 'Industrial',
    content: 'Projects Slider Here'
  },
  {
    id: 'oilsector',
    icon: 'fas fa-oil-can',
    title: 'Oil Sector',
    content: 'Projects Slider Here'
  },
  {
    id: 'anc',
    icon: 'fas fa-building',
    title: 'Administrative',
    content: 'Projects Slider Here'
  },
  {
    id: 'hospitals',
    icon: 'fas fa-hospital',
    title: 'Hospitals',
    content: 'Projects Slider Here'
  },
  {
    id: 'residential',
    icon: 'fas fa-home',
    title: 'Residential',
    content: 'Projects Slider Here'
  },
  {
    id: 'tourism',
    icon: 'fas fa-camera',
    title: 'Tourism',
    content: 'Projects Slider Here'
  },
  {
    id: 'tnc',
    icon: 'fas fa-bus',
    title: 'Transportation',
    content: 'Projects Slider Here'
  }
];
function VT(props) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataimg, setdataimg] = useState([]);
  const [id, setid] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);


  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const token = props.props;
        const response = await fetch("https://islandbe.onrender.com/api/projects?populate=*", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        const myData = [];
        // console.log("---------------------------JSON DATA FOR PROJECTS---------------------------");
        // console.log(jsonData)
        jsonData.data.forEach(element => {
          const temp = {
            image:element?.attributes?.image?.data?.attributes.url,
            caption: element?.attributes?.name,
            type: element?.attributes?.type,
            date: element?.attributes?.creationDate,
            details: element?.attributes?.subtitle,
            location: element?.attributes?.location,
            imgid: element?.attributes?.project_image?.data?.id
          }
          // console.log("---------------------------vertical tabs---------------------------");
          // console.log(temp);
          myData.push(temp);
        });
        setData(myData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    async function fetchImageData() {
      try {
        const token = props.props;
        const response = await fetch("https://islandbe.onrender.com/api/project-imagess?populate=*", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        const myData = [];
        jsonData.data.forEach(element => {
          // const temp = {
          //   image: "http://localhost:1337" + element.attributes.image.data.attributes.url,
          //   caption: element.attributes.name,
          //   type: element.attributes.type,
          //   date: element.attributes.creationDate,
          //   details: element.attributes.subtitle,
          //   location: element.attributes.location,
          //   imgid: element?.attributes?.project_image?.data?.id
          // }
          // console.log("el", element?.attributes?.media?.data);
          myData.push(element);
        });
        setdataimg(myData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    fetchData();
    fetchImageData();
  }, []);

  return (

    <div className='vt'>
      <div className="container">
        <div className="tabs">
          {tabs.map(tab => (

            <button
              key={tab.id}
              className={`tab ${tab.id === activeTab.id ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              <span className="icon">
                <i className={tab.icon}></i>
              </span>
              <span className="title">{tab.title}</span>
            </button>
          ))}
        </div>
        <div className="content">
          <Swiper
            slidesPerView={1}

            centeredSlides={true}
            spaceBetween={5}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {
              data
                .filter(element => element.type == activeTab.title)
                .map((element, index) => (

                  <SwiperSlide key={index}>
                    <div className="card" style={{
                      backgroundImage: "url(" + element?.image + ")", backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height: '100%',
                    }}>

                      <div className="card__details" style={{ background: 'rgba(255,255,255,0.7)' }}>
                        <span className="tag"><i class="fa fa-calendar" aria-hidden="true"></i> {element?.date?.split('-')[0]}</span>
                        <span className='tag'><i class="fa fa-map-marker" aria-hidden="true"></i> {element?.location}</span>
                        <span className="tag"><a href="#" title="Quick View" data-bs-toggle="modal" onClick={() => setid(element?.imgid)} data-bs-target="#gallery"><i className='fas fa-images'/> Gallery</a></span>

                        <div className="name">{element?.caption}</div>
                        {element?.details}
                      

                      </div>
                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>


        </div>
      </div>
      <div className="ltn__modal-area ltn__quick-view-modal-area">
        <div className="modal fade" id="gallery" tabIndex={-1}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content" style={{    background: 'rgba(0,0,0,0.8)'}}>
              <div className="modal-header" style={{'padding':'0px'}}>
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" style={{'zIndex':'999'}}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body" style={{'padding':'0px'}}>
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="sp">
                        <Swiper
                          style={{
                            "--swiper-navigation-color": "#fff",
                            "--swiper-pagination-color": "#fff",
                          }}
                          loop={true}
                          spaceBetween={10}
                          navigation={true}
                          thumbs={{ swiper: thumbsSwiper }}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper2"
                        >
                          {dataimg
                            .filter((element) => element.id === id)
                            .map((element, index) => {
                              return element?.attributes?.media?.data?.map((img, i) => (
                                <SwiperSlide key={i}>
                                  <img src={img?.attributes?.url}></img>
                                </SwiperSlide>
                              ));
                            })}
                        </Swiper>
                        <Swiper
                          onSwiper={setThumbsSwiper}
                          loop={true}
                          spaceBetween={10}
                          slidesPerView={4}
                          freeMode={true}
                          watchSlidesProgress={true}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper"
                        >
                          {dataimg
                            .filter((element) => element.id === id)
                            .map((element, index) => {
                              return element?.attributes?.media?.data?.map((img, i) => (
                                <SwiperSlide key={i}>
                                  <img src={ img?.attributes?.url}></img>
                                </SwiperSlide>
                              ));
                            })}
                        </Swiper>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
export default VT;
