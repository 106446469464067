import React from 'react'
import Homepage from "./components/MainPages/Homepage";
import Projects from "./components/MainPages/Projects";
import Page404 from "./components/MainPages/Page404";
import {BrowserRouter as Router, Route, Routes, useParams} from 'react-router-dom';

import Preloader from "./components/Preloader";
import Navbar from "./includes/Navbar"
import Footer from "./includes/Footer"
import "animate.css/animate.min.css";


function App() {
  //change this token to reflect your changes across all app :) 
 const TOKEN="3714d4ca7aef85822fc883b1a5b0e40aa9a6f733c0676f3fb0b774976fdb773104614e6286496a463d31ab5e890b0f963aa909bbb0e1d2c11b6e1a0f21f3d5b9bb3698b98bcafa75c98acd8af429873a9f2688db08d9127082e5ab1eb9f270c0eabfe60b3b541e991f2a81b729d4644f91d112fd7bd87dc2299f69e85abbb94c";


  return (
    <div className="body-wrapper">
    <Router>
      <Preloader/>
      <Navbar  props={TOKEN}/>
        <Routes>
          <Route path='/' element={<Homepage  props={TOKEN} />} />
          <Route path='/category/:categoryId/' element={<Projects props={TOKEN}/>} />
          <Route path='*' exact={true} element={<Page404/>} />
        </Routes>
      <Footer props={TOKEN} />
    </Router>
  </div>
  );
}

export default App;
