import React from 'react'

function Preloader() {
  return (
 
    <div className="preloader" id="preloader">
        <div className="preloader-inner">
          {/* <img src="img/new/Engineer.gif" alt="preloader"/> */}
          <svg width="914" height="100%" viewBox="0 0 914 633" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M124.074 534.059L208.618 401.805L345.076 198.782L465.325 294.896L124.074 534.059Z" fill="#D9D9D9" stroke="#00215B" stroke-width="23" class="svg-elem-1"></path>
<path d="M771.507 518.64L341.472 204.561L457.48 21.5083L771.507 518.64Z" stroke="#00215B" stroke-width="23" class="svg-elem-2"></path>
</svg>

        </div>
    </div>
  );
}

export default Preloader;
