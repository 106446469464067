import React, { useState, useEffect } from 'react';


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode, Thumbs } from "swiper";
import './NewsCard.css'

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
const host = 'http://192.168.1.36:1337';

function Timestamp({ timestamp }) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);
  
    // Format the date as a string
    const dateString = date.toLocaleString();
  
    return (
      <time style={{textAlign:'left'}} dateTime={timestamp}>{dateString}</time>
    );
  }



function News(props) {
    const [data, setData] = useState([]);
    const [link, setlink] = useState("");
    const [title, settitle] = useState("");
    const [text, settext] = useState("");
    const [date, setdate] = useState("");
    const [caption, setcaption]=useState("");

    console.log(props);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [slidesPerView, setSlidesPerView] = useState(2);



    useEffect(() => {
        const updateSlidesPerView = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 768) {
                setSlidesPerView(1);
            } else if (screenWidth < 1024) {
                setSlidesPerView(2);
            } else {
                setSlidesPerView(3);
            }
        };

        updateSlidesPerView();

        window.addEventListener('resize', updateSlidesPerView);

        return () => {
            window.removeEventListener('resize', updateSlidesPerView);
        };
    }, []);


    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }



    useEffect(() => {
        async function fetchData() {
            try {
                const token = props.props;
                const response = await fetch('https://islandbe.onrender.com/api/newss?populate=*', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                const myData = jsonData;
                console.log("news", myData);
                setData(myData);
                setLoading(false);
                console.log("this is the data", data);

            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }

        fetchData();

    }, []);

    return (
        <>
            <div className="ltn__modal-area ltn__quick-view-modal-area">
                <div className="modal fade" id="news123" tabIndex={-1}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header" style={{ 'padding': '0px' }}>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" style={{ 'zIndex': '999' }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ 'padding': '0px' }}>
                                <div className="ltn__quick-view-modal-inner">
                                    <div className="modal-product-item">

                                        <article className='post'>
                                            <div>
                                                <img src={link}></img>
                                            </div>
                                            <div className="post__container">
                                                <span style={{ color: 'gray' }} className="post__category">{caption}</span>

                                                <div className="post__content">
                                                    <header>
                                                    <Timestamp timestamp={date} />

                                                        <h1 style={{ color: 'black'}} className="post__header">{title}</h1>
                                                    </header>

                                                    <p style={{ color: 'black'}} className="post__text">{text}</p>
                                                </div>
                                            </div>
                                        </article>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Swiper
                slidesPerView={slidesPerView}

                centeredSlides={true}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >

                {
                    data?.data?.map((element, index) => {


                        var isHovered = false;
                        const date = new Date(element?.attributes?.publishedAt);
                        const day = date.getDate();
                        const month = date.toLocaleString('default', { month: 'short' });

                        function handleMouseEnter() {
                            isHovered = true;
                        }

                        function handleMouseLeave() {
                            isHovered = false;
                        }


                        return (

                            <SwiperSlide key={index}>

                                {/* <figure className={`snip1369 blue ${isHovered ? "hover" : ""}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <div class="image">
                                <img src={'http://localhost:1337' + element?.attributes?.media?.data?.attributes?.url} alt="pr-sample18" />
                            </div>
                            <figcaption>
                                <h3>{element?.attributes?.title}</h3>
                                <p>{element?.attributes?.text}</p>
                            </figcaption><span class="read-more">

                                Read More <i class="ion-android-arrow-forward"></i></span>
                            <a href="#"></a>
                        </figure> */}
                                <figure className={`snip1529  ${isHovered ? "hover" : ""}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    <img src={ element?.attributes?.media?.data?.attributes?.url} alt="pr-sample13" />
                                    <div className='date'><span className='day'>{day}</span><span className='month'>{month}</span></div>
                                    <figcaption>
                                        <h3>{element?.attributes?.title}</h3>
                                        <p>{element?.attributes?.text}</p>
                                    </figcaption>
                                    <div className='hover'><i className='ion-android-open'></i></div>
                                    <a href="#" title="Quick View" onClick={() => {
                                        setlink(element?.attributes?.media?.data?.attributes?.url);
                                        settitle(element?.attributes?.title);
                                        settext(element?.attributes?.text);
                                        setdate(element?.attributes?.publishedAt);
                                        setcaption(element?.attributes?.caption)
                                    }} data-bs-toggle="modal" data-bs-target="#news123"></a>
                                    {/* <a href={'http://localhost:1337'+element?.attributes?.link?.data?.attributes?.url} target="_blank"></a> */}


                                </figure>

                            </SwiperSlide>


                        )



                    })
                }
            </Swiper>

        </>
    );
}
export default News;
