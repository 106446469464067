import React, { Component } from 'react';
import VT from "./VerticalTabs";
import News from './News';
import Clients from './Clients';




class Homepage extends Component {
  constructor(props) {
    super(props);
     console.log(props);
    this.state = {
      data: [],
      news: [],
  

     
    };
  }

//token 
//  TOKEN="ef614edcfa26d25760c486cbeca0c02e801632b33a0534d5ead5c7e8c5b8a3c76061cff2dc0beaa229253d910d3aefa886a3fe1d0e622dc946e8944a5965973e09c6b86f340b76325c22e01ba6f34d0362989ea9426a71a7df264dc5408b4b6e0383578868c96af447ecfca253997e8abf440946ed12a7283ba4b698568a664e";
  PROPS;
 // http options
  httpOptions={
    headers: {
           Authorization: `Bearer ${this.props.props}`,
    }
}



  async componentDidMount() {

     



    try {
      var res_news = [];

        
      


      // const settings = {
      //   dots: true,
      //   infinite: true,
      //   speed: 500,
      //   slidesToShow: 1,
      //   slidesToScroll: 1
      // };
      // const innerSettings = {
      //   dots: true,
      //   infinite: true,
      //   speed: 500,
      //   slidesToShow: 1,
      //   slidesToScroll: 1
      // }
      // const news = [];
      // console.log(news);
      // this.setState({
      //   news,
      //   settings,
      //   innerSettings
      // });
    } catch (e) {
      console.log(e);
    }
  }

  // changePic(id) {
  //   document.getElementById(id).src = "img/logos/colored/" + id + ".svg";
  // }
  // goback(id) {
  //   document.getElementById(id).src = "img/logos/gray/" + id + ".svg";
  // }

  render() {
     const data = this.state.data; 
    const news = this.state.news; 
     const setting = this.state.settings; 
    const innerSetting = this.state.innerSettings; 

 









    
 






    return (<div>
      {/* HOME */}
      <div className="ltn__slider-area ltn__slider-3 text-center section-bg-2">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
          {/* ltn__slide-item */}
          <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-30" data-bs-bg="img/new/33.JPG" id="home">
            <div className="ltn__slide-item-inner  text-left">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        {/* <img src='img/new/logo-removebg-preview-removebg-preview-removebg-preview.png' className='logo-slider' /> */}
                        <h1 className="slide-title animated ">THE <b>CONSTRUCTION</b> <br />  YOU COUNT ON OVER <strong>20 YEARS</strong></h1>
                        <div className="slide-brief animated">
                          <p>Has accumulated a wide and diversed experience over the years.</p>
                        </div>
                        <div className="btn-wrapper animated">
                          <a href="#about" className="theme-btn-1 btn btn-effect-1">Learn more</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ABOUT */}

      <div class="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11 slick-slide slick-current slick-active" tabindex="-1" role="option" aria-describedby="slick-slide02" data-slick-index="2" aria-hidden="false">
        <div class="ltn__slide-item-inner">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 align-self-center">
                <div class="slide-item-info">
                  <div class="slide-item-info-inner ltn__slide-animation">
                    <div class="slide-video mb-50 d-none">
                      <a class="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection" tabindex="0">
                        <i class="fa fa-play"></i>
                      </a>
                    </div>
                    <h6 class="slide-sub-title white-color--- animated"><span><i class="fas fa-home"></i></span> Real Estate Agency</h6>
                    <h1 class="slide-title animated ">Find Your Dream <br /> House By Us</h1>
                    <div class="slide-brief animated">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    </div>
                    <div class="btn-wrapper animated">
                      <a href="service.html" class="theme-btn-1 btn btn-effect-1" tabindex="0">OUR SERVICES</a>
                      <a href="about.html" class="btn btn-transparent btn-effect-3" tabindex="0">LEARN MORE</a>
                    </div>
                  </div>
                </div>
                <div class="slide-item-img">
                  <img src="img/slider/63.jpg" alt="#" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="ltn__product-slider-area ltn__product-gutter pt-50 pb-40 plr--7 bg-image  about-ceo" id='about'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src='img/about/about-us2.png'></img>

            </div>
            <div className='col-lg-6 flex'>
              <div className='vertical-center'>
                <h2>We‘re providing the best
                  customer service</h2>

                <div className='box'>
                  <p>
                    Being one of the leading regional private construc- tion firms in Egypt and the Middle East, ISLAND for contracting Company, has accumulated a wide and diversified experience over the years. ISLAND has not only succeeded in completing the given projects in a timely and effective manner, but also has become a major competitor in the market whilst building re- spect among its competitors, clientele, and employees alike.<br />
                    <br />
                    <b>Eng Mahmoud</b>, CEO
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__product-slider-area ltn__product-gutter pt-50 pb-40 about-col" id='quality-controls'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col1">
            </div>
            <div className="col-lg-2 iso bg-overlay-theme-black-90">
              <h3>ISO</h3>
              <p>To accomplish this mission, the General Management has set up a Quality System, operating in compliance with ISO 9001 standard.
              </p>
            </div>
            <div className="col-lg-2  quality-control bg-overlay-theme-black-05">
              <h3>Quality Control</h3>
              <p>
                <b>ISLAND</b> implements an advanced management system and planning techniques to optimize the usage of time and resources.

              </p>
            </div>
            <div className="col-lg-2 bv bg-overlay-theme-black-90">
              <h3>Business Volume</h3>
              <p><b>ISLAND</b>’s commitment of honor holds the management responsible to the effective management of Health, Safety and Environment issues as an integral part of our policies, philosophy and procedures.</p>
            </div>

          </div>
        </div>
      </div>
      {/* PROJECTS */}
      <div className="ltn__banner-area pt-4" id="projects">
        <div className="container" style={{ 'paddingBottom': '40px' }}>
          <div className="section-title-area ltn__section-title-2--- text-center">
            <h2 className="section-title">PROJECTS</h2>
          </div>

          <VT props={this.props.props} />



        </div>
      </div>


      <div className="ltn__product-slider-area ltn__product-gutter pt-50 pb-40 plr--7 bg-image bg-overlay-theme-black-90" data-bs-bg="img/new/cco.jpg" id='aboutus'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h4 className="section-title text-color-white">About Us</h4>
                <p className='text-color-white'>We cover all Egypt Governorates, Arab and African Countries.
                  The Company Business Volume is now approx 200, 000 000 LE
                </p>
                {/* <h6 className='text-color-white'> You can view our company chart  <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#chart" ><span style={{color:'grey'}}>here!</span></a></h6> */}

              </div>
            </div>
          </div>
          <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">
            {/* ltn__product-item */}
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-4 text-center---">

                <div className="product-info">
                  <h2 className="product-title" title="Quick View" data-bs-toggle="modal" data-bs-target="#mission"><a href="#">MISSION</a>
                  </h2>
                  <div className="product-description">
                    {/* <p>• We will work to deliver exceptional value for our shareholders by following
                      a coherent and considered strategy. <br />
                      • Targeting large, complex construction projects in foreigners’ markets. <br />
                      • Working in partnership with local and global leaders.<br />
                      • Maintaining our commitment to quality and safety.<br />
                      • Being a good corporate citizen wherever we operate. <br />
                      • Providing products and services for people in developing economies. <br />
                      • Searching for new opportunities in order to deliver exceptional value. <br />
                      • We believe that our development is strongly related to the development of our human resources through training. <br />
                      • Promoting an environment of competitiveness amongst our employees to
                      promote their sense of leadership and team working.
                    </p> */}
                    <p>
                      Our mission is to deliver value, target large complex construction, and to partner with global and local leaders to maintain quality and safety. We provide  good corporate citizen, products, and services for people in developing economies through our in-house human resource training and environment of competitiveness within our company.
                    </p>
                  </div>
                </div>
                <div className="product-info-bottom">
                  <div className="real-estate-agent">
                    <div className="agent-brief">
                      <h6><a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#mission">Read more</a></h6>
                    </div>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#mission">
                          <i className="flaticon-expand" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* ltn__product-item */}
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-4 text-center---">

                <div className="product-info">
                  <h2 className="product-title" title="Quick View" data-bs-toggle="modal" data-bs-target="#vision"><a href="#">VISION</a>
                  </h2>
                  <div className="product-description">
                    <p>• We aspire within the next five years to be recognized as the contractor of
                      choice for large, complex and demanding construction projects in the Middle
                      East as Egypt. <br />
                      • We are committed to delivering quality work and products, safely and on
                      schedule.<br />
                      • A company that our clients want work with, our customers can rely on
                      through high quality & healthy working environment and our employees are
                      proud to work for.<br />
                      • A  company with an open mind ready to embrace new opportunities and
                      driven to deliver exceptional value<br />
                      •  A company transforms the way people live and work through encouraging
                      innovation and challenging convention.<br /><br /><br />
                    </p>
                  </div>
                </div>
                <div className="product-info-bottom">
                  <div className="real-estate-agent">
                    <div className="agent-brief">
                      <h6>
                        <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#vision">Read more</a></h6>
                    </div>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#vision">
                          <i className="flaticon-expand" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__product-item ltn__product-item-4 text-center---">

                <div className="product-info">
                  <h2 className="product-title" title="Quick View" data-bs-toggle="modal" data-bs-target="#values"><a href="#">OUR VALUES</a>
                  </h2>
                  <div className="product-description">
                    <p>
                      <strong>Achievement:</strong>
                      We are results oriented and are committed to creatinng value and return on investment
                      for our shareholders and clients. We do not compromise on quality and do our diligence in all processes in every
                      level of the organization to ensure excellence in the end result. <strong style={{ color: 'var(--black-2)' }}>WE DELIVER</strong> <br />
                      <strong>Pro-activity:</strong> We are forward thinking and initiate change in the market instead of reacting to changing the market
                      conditions after they have become a reality. We are committed to initiate new ideas and projects to help
                      contribute to our own and the company’s success. We lead and inspire others to perform and encourage working outside our defiend scope.<strong style={{ color: 'var(--black-2)' }}>WE LEAD</strong> <br />
                      <strong>Creativity:</strong> We believe in structure but insist on free thinking and entrepreneurship.
                      We encourage new ideas and out-of-the-box thinkers.<strong style={{ color: 'var(--black-2)' }}>WE DO THINGS DIFFERENTLY</strong>
                    </p>
                  </div>
                </div>
                <div className="product-info-bottom">
                  <div className="real-estate-agent">
                    <div className="agent-brief">
                      <h6><a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#values">Read more</a></h6>
                    </div>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#values">
                          <i className="flaticon-expand" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* SAFTEY */}

      <div className="ltn__testimonial-area section-bg-2--- bg-image-top pt-115 pb-25 bg-overlay-black-50 " data-bs-bg="img/new/saftey.jpg" id="h&s">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color--- white-color">HEALTH, SAFETY & ENVIRONMENT MANAGEMENT</h6>
                <h1 className="section-title white-color">SEE HOW WE PROVIDE <br />SAFTEY</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-6-active slick-arrow-3">
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
                <div className="ltn__testimoni-info">
                  <p>
                    Our health, safety and environmental management system safeguards our
                    day-to-day activities and is underpinned by clear communication and training
                    to employees and others to raise awareness on key issues. The system allows
                    ISLAND to sustain, assess, and review how well it is integrating international
                    standards of health, safety and environmental excellence (such as ISO 14001
                    and OHSAS 18001) into the company’s business and projects.
                  </p>
                  <h6><a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#safety">Read more</a></h6>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__product-slider-area ltn__product-gutter pt-50 pb-40 plr--7" id='about'>
        <div className="container">
          <div className="row">

            <div className="col-lg-7">
              <div className="ltn__product-item text-center---">

                <div className="product-info">
                  <h2 className="product-title"><a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#hr">HUMAN RESOURCES</a>
                  </h2>
                  <div className="product-description">
                    <p>
                      Construction is about long-term investment. We work together with our
                      employees, clients, business partners, suppliers and communities to ensure
                      that the highest standard of professional conduct and ethic is embedded in
                      everything we do.<br />
                      Each individual employee plays a key role in <strong>ISLAND</strong>’s success. Our employees are part of a corporate culture that challenges and encourages everyone.
                      Working together, we are focused on meeting our responsibilities as a market leading business to contribute to sustainable development in the markets we
                      operate in.<br />
                      <br />
                      We believe that the success of <strong>ISLAND</strong> is based on the skills and quality of
                      its professional team and is one of its main competitive advantages. Accordingly, <strong>ISLAND</strong> maintains its commitment to continually improve their skills,
                      capacities, commitment and motivation, always with the highest attention to
                      work and safety conditions.
                      To optimize the untapped human resources has been the added value of
                      <strong> ISLAND</strong> since its foundation. This formula has formed the cornerstone of the
                      Company’s operation and success. As such, the management has undertaken
                      to ...
                    </p>
                  </div>
                </div>
                <div className="product-info-bottom">
                  <div className="real-estate-agent">
                    <div className="agent-brief">
                      <h6><a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#hr">Read more</a></h6>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className='col-lg-5' style={{ 'alignSelf': 'center' }}>
              <img className='bg-overlay-theme-black-80' src="img/new/hr.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div></div>
   

      {/* start my clients */}

      <div className='py-3'>
        <Clients props={this.props.props} ></Clients>
      </div>

      {/* end my clients */}


      

      

      {/* NEWS */}
      <div className="ltn__blog-area pt-90 pb-70 section-bg-2" id="news">
        <div className="container">

          <div className="section-title-area ltn__section-title-2--- text-center">
            <h6 className="section-subtitle section-subtitle-2">News &amp; Blogs</h6>
            <h1 className="section-title">LATEST NEWS</h1>
            <News props={this.props.props} />

          </div>
        </div>
      </div>





    </div>
    );
  }
}

export default Homepage;
