import React , { useState, useEffect } from 'react';

function Footer(props) {
  const [data, setData] = useState([]);


  // let viewData={
  //   landline_1:"",
  //   landline_2:"",
  //   fax:"",
  //   phone_1:"",
  //   phone_2:"",
  //   address_1:"",
  //   address_2:"",
  //   email:"",
  // }
  



  useEffect(() => {
    async function fetchData() {
        try {
            const token = props.props;
            const response = await fetch('https://islandbe.onrender.com/api/contact-infos?populate=*', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            const myData = [];
            jsonData.data.forEach(element => {
              const temp={
                landline_1:element?.attributes?.landline1,
                landline_2:element?.attributes?.landline2,
                fax:element?.attributes?.fax,
                phone_1:element?.attributes?.phonenumber1,
                phone_2:element?.attributes?.phonenumber2,
                address_1:element?.attributes?.address,
                address_2:element?.attributes?.address,
                email:element?.attributes?.email,

              }
              myData.push(temp);
            });
            console.log("contact", myData);
         

          // only bind to the first object of the array
           setData(myData[0]);
           

        } catch (error) {
            // setError(error);
            console.log(error)
            // setLoading(false);
        }
    }

    fetchData();

}, []);




  return (

    <footer className="ltn__footer-area" id="contact">
  <div className="footer-top-area  section-bg-1 plr--5">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-4 col-md-6 col-sm-6 col-12">
          <div className="footer-widget footer-about-widget">
            <div className="footer-logo">
              <div className="site-logo">
                <img src="img/new/logonobg.png" alt="Logo" />
              </div>
            </div>
            <p>Feel free to contact us.</p>
            <div className="footer-address pt-10">
              <ul>
                <li>
                  <div className="footer-address-icon">
                    <i className="icon-placeholder" />
                  </div>
                  <div className="footer-address-info">
                  <a href="https://www.google.com/maps/dir/30.047652,31.4570441/Island+Agencies+%26+Services,+26+Aden+St,+Giza+Governorate/@30.029835,31.1855104,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14584136222ead29:0x60e0447f0acde3fa!2m2!1d31.1949225!2d30.0518536">
                    {data?.address_2}
                    <br/>
                    {/* {viewData?.address_2} */}

                    </a>
                  </div>
                </li>
                {/* <li>
                  <div className="footer-address-icon">
                    <i className="icon-call" />
                  </div>
                  <div className="footer-address-info">
                    <p><a href="tel:+201273890100">+(2) 012 - 7389- 0100</a></p>
                  </div>
                </li> */}
                <li>
                  <div className="footer-address-icon">
                    <i className="icon-mail" />
                  </div>
                  <div className="footer-address-info">
                    <p><a href={`mailto:${data?.email}`} >{data?.email}</a></p>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className="ltn__social-media mt-20">
              <ul>
                <li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                <li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
                <li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
                <li><a href="#" title="Youtube"><i className="fab fa-youtube" /></a></li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="col-xl-4 col-md-6 col-sm-6 col-12">
          <div className="footer-widget footer-menu-widget clearfix">
            <h4 className="footer-title">QUICK LINKS</h4>
            <div className="footer-menu">
              <ul>
                <li><a href="#safety"> Health & Safety</a></li>
                <li><a href="#about">Quality Control</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#news">News</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-md-6 col-sm-6 col-12">
          <div className="footer-widget footer-menu-widget clearfix">
            <h4 className="footer-title">CONTACT US</h4>
            <div className="footer-menu">
              <ul>
                <li>
                    <a className='py-3' href={"tel:+"+data?.landline_2}>{"Landline 1 - " +data?.landline_1}</a> &nbsp;
                     <br/>
                    <a href={"tel:+"+data?.landline_2}>{"Landline 2 - " +data?.landline_2}</a>
                </li>
                <li><a href="#">{"Fax - "+data?.fax}</a></li>
                <li><a href={"tel:+"+data?.phone_1}>{"Phone 1 - "+data?.phone_1}</a> &nbsp; <br/>
                    <a href={"tel:+"+data?.phone_2}>{"Phone 2 - "+data?.phone_2}</a></li>
                <li>
                  <a href="https://www.google.com/maps/dir/30.047652,31.4570441/Island+Agencies+%26+Services,+26+Aden+St,+Giza+Governorate/@30.029835,31.1855104,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14584136222ead29:0x60e0447f0acde3fa!2m2!1d31.1949225!2d30.0518536">
                   {data?.address_1}
                  </a>
                  </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-4 col-md-6 col-sm-6 col-12">
          <div className="footer-widget footer-menu-widget clearfix">
            <h4 className="footer-title">COMPANY</h4>
            <div className="footer-menu">
              <ul>
                <li><a href="#about">About</a></li>
                <li><a href="https://www.google.com/maps/dir/30.047652,31.4570441/Island+Agencies+%26+Services,+26+Aden+St,+Giza+Governorate/@30.029835,31.1855104,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14584136222ead29:0x60e0447f0acde3fa!2m2!1d31.1949225!2d30.0518536">Locations Map</a></li>
                <li><a href="#">Fax - (+202) 333-563-90</a></li>
              </ul>
            </div>
          </div>
        </div> */}

        {/* <div className="col-xl-3 col-md-6 col-sm-6 col-12">
          <a href="#">
          <div className="footer-widget footer-menu-widget clearfix">
            <img src="img/new/map.jpg" role="..." alt="..." className="map-img"/>
          </div>
          </a>
        </div> */}
      </div>
    </div>
  </div>
  <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
    <div className="container-fluid ltn__border-top-2">
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="ltn__copyright-design clearfix">
            <p>All Rights Reserved &copy; Island <span className="current-year" /></p>
          </div>
        </div>
        {/* <div className="col-md-6 col-12 align-self-center">
          <div className="ltn__copyright-menu text-end">
            <ul>
              <li><a href="#">Terms &amp; Conditions</a></li>
              <li><a href="#">Privacy &amp; Policy</a></li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</footer>

  );
}

export default Footer;
