import React, { Component, useState, useEffect} from 'react';
 import "../clientsSection.css";

class Clients extends Component {
  constructor(props) {
     super(props);
    this.state = {
      carouselData: [],
    };
  

 console.log(props)

  }

  clientsList=[];
 

  componentDidMount() {


    this.fetchData();
    
  }

  fetchData = async () => {
    try {
      const response = await fetch('https://islandbe.onrender.com/api/clients?populate=*',this.httpOptions);
      const data = await response.json();
      console.log(data);
      this.generateClientsListLogos(data);
      // this.setState({ carouselData: data });
      this.handleMultipleSlides();

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // changePic = (index) => {
  //   this.setState({ activeClient: index });
  // }

  // goback = () => {
  //   this.setState({ activeClient: null });
  // }

  httpOptions={
    headers: {
           Authorization: `Bearer ${this.props.props}`,
    }
  }

  generateClientsListLogos(clientsDataObj){
     clientsDataObj.data.forEach(client => {
      this.clientsList.push(client?.attributes?.coloredclientlogo?.data?.attributes?.url)
    });
  
    this.setState(() => ({
      carouselData: [...this.clientsList],
      
    }));
  
    console.log(this.clientsList);
    
  }

  handleMultipleSlides = () => {
    const items = document.querySelectorAll('.carousel .carousel-item');

    items.forEach((el) => {
      const minPerSlide = 4;
      let next = el.nextElementSibling;

      for (let i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0];
        }

        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  };


  render() {
    const { carouselData } = this.state;











    return (
      <div className="container text-center my-3">

              <h2 className="font-weight-light">Our Clients</h2>
              <div className="row mx-auto my-auto justify-content-center">
                  <div id="recipeCarousel" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner" role="listbox">
                         
                      {carouselData.map((item, index) => (
                          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <div className="col-lg-3 col-md-3 col-12">
                              <div className="card">
                                <div className="card-img">
                                  <img src={item} alt={`Slide ${index + 1}`} className="img-fluid greyScale" />
                                </div>
                                
                              </div>
                            </div>
                          </div>
                         ))}
                   
                         
                      </div>
                      <a className="carousel-control-prev  w-aut" href="#recipeCarousel" role="button" data-bs-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      </a>
                      <a className="carousel-control-next  w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      </a>
                  </div>
              </div>
          </div>
    
     
     
    );
  }
}

export default Clients;